import { createElement, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Field as FormikField, getIn, useFormikContext } from 'formik';
import toPath from 'lodash/toPath';
import { createFieldId, createErrorMsgId } from '../helpers';
import { useFieldValidation } from '../validation';
import { fieldPropTypes } from './propTypes';

const Field = ({ fieldName, fieldTitle, initialValue, validation, ...fieldProps }) => {
  const formik = useFormikContext();
  const fieldPath = toPath(fieldName);
  const presentValue = getIn(formik.values, fieldPath);
  const [handleBlur, handleKeyDown, handleChange] = useMemo(() => {
    let tabKeyPressed = false;

    const handleBlur = e => {
      const currentActiveElement = e.relatedTarget || document.activeElement;
      const isSubmitElementFocused = currentActiveElement && currentActiveElement.type === 'submit';

      if (isSubmitElementFocused && !tabKeyPressed) {
        tabKeyPressed = false;
        return;
      }

      const shouldValidate = !(currentActiveElement && currentActiveElement.hasAttribute('data-prevent-validation'));

      if (shouldValidate)
        formik.handleBlur(e);

      tabKeyPressed = false;
    };

    const handleKeyDown = e => tabKeyPressed = isTabKeyPressed(e);

    // Apply 'defaultValue' prop in target node to make autofill work in FF and IE.
    // FF and IE use specific comparison of that prop and 'value' and in case they are equal
    // browsers don't save filled data. React uses this property for reset functionality, but in case we use Formik
    // it shouldn't be a problem for us. For details refer to GitHub issue https://github.com/facebook/react/issues/18986.
    const handleChange = e => {
      Object.defineProperty(e.target, 'defaultValue', {
        configurable: true,
        get() { return ''; },
        set() { },
      });

      formik.handleChange(e);
    };

    return [handleBlur, handleKeyDown, handleChange];
  }, []);

  useEffect(() => {
    if (getIn(formik.values, fieldPath) !== undefined)
      return;

    formik.setFieldValue(fieldName, initialValue === undefined ? '' : initialValue, false);
  }, [fieldName, presentValue]);

  const hasError = !!getIn(formik.errors, fieldPath) && !!getIn(formik.touched, fieldPath);
  const fieldId = createFieldId(formik.status.formName, fieldName);
  if (hasError) {
    const errorId = createErrorMsgId(formik.status.formName, fieldName);
    fieldProps['aria-describedby'] = errorId + (fieldProps['aria-describedby'] || '');
  }

  const formikFieldProps = {
    id: fieldId,
    name: fieldName,
    'aria-invalid': hasError,
    onBlur: handleBlur,
    onKeyDown: handleKeyDown,
    onChange: handleChange,
    ...fieldProps,
  };

  const validate = useFieldValidation(fieldName, fieldTitle, validation, { api: formik.status.api });
  if (validate)
    formikFieldProps.validate = validate;

  useEffect(() => {
    if (validate)
      formik.validateField(fieldName);
  }, [validate]);

  if (presentValue === undefined)
    formikFieldProps.value = initialValue === undefined ? '' : initialValue;

  return createElement(FormikField, formikFieldProps);
};

Field.propTypes = {
  ...fieldPropTypes,
  initialValue: PropTypes.any,
  validation: createValidationPropTypes(PropTypes.object),
};

export default Field;

function isTabKeyPressed(e) {
  return e.key === 'Tab';
}

function createValidationPropTypes(typeValidator) {
  return (props, propName, componentName, ...args) => {
    const validation = props[propName];

    const formikValidate = props['validate'];
    if (validation != null && formikValidate != null)
      return new Error(`Unexpected prop \`validate\` passed to \`${componentName}\` while using \`${propName}\`.
        \`validate\` prop is omitted when validation schema is provided.
        Consider using property \`custom\` in validation schema object passed as \`${propName}\` prop.`);

    return typeValidator(props, propName, componentName, ...args);
  };
}
